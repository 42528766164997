<template>
   <spinnerWeb :loading="this.$store.state.status_loading"/>
   <router-view />
</template>
<script>
import { ref,onMounted } from 'vue'
import { getAuth ,onAuthStateChanged } from 'firebase/auth';
import { useStore } from 'vuex'
import SpinnerWeb from '@/components/SpinnerWeb'
export default {
  components :{
    SpinnerWeb,
  },
  setup(){
    const isLoggeIn = ref(false);
    const store = useStore()
    let auth;

    onMounted(()=>{
      auth = getAuth()
      onAuthStateChanged(auth ,(user)=>{
        if(user){
          isLoggeIn.value = true;
          store.dispatch('action_set_user',user)
        }else{
          isLoggeIn.value = false;
        }
      })
    })
    return {
      isLoggeIn,
      auth,

    }
  },
  beforeCreate(){
    this.$store.dispatch('getNftData')
  }
}
</script>

<style>
*{
  margin : 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
