import { createStore } from 'vuex'
import router from '../router'
import { createUserWithEmailAndPassword ,signInWithEmailAndPassword ,signOut } from 'firebase/auth'
import { auth,nftsRef,db } from '../firebase'
import { getDocs,doc,getDoc,collection,orderBy,query } from 'firebase/firestore'

export default createStore({
  state: {
    user : null,
    nftData : null,
    blank_nftData : null,
    status_loading : true,
    nftDataDetail : null,
    language : null,
    select_by_status : 'All', // All=모든프젝 , minting=출시전 ,release=출시후
    select_by_chain : 'All', // All=모든프젝 , eth=이더리움 ,klay=클레이튼,terra=테라,sol=솔라나
    sort_option : '_create_date', // _create_date=최신순 , create_date=과거순,     
    list_show_count : 20,
  },

  mutations: {
    SET_USER(state,user){
      state.user = user
    },
    CLEAR_USER(state){
      state.user = null;
    },
    SET_ON_LOADING(state){
      state.status_loading = true
    },
    SET_OFF_LOADING(state){
      state.status_loading = false
    },
    SET_NFT_DATA(state,data){
      state.nftData = data
    },
    SET_BLANK_NFT_DATA(state,data){
      state.blank_nftData = data
      
    },
    SET_NFT_DETAIL_DATA(state,data){
      state.nftDataDetail = data
    },
    SET_SELECT_BY_STATUS(state,data){
      state.select_by_status = data
    }

  },
  actions: {
    action_set_user({commit},data){
      commit('SET_USER',data)
    },
    async login({commit},details){
      const { email,password } = details
      try {
        await signInWithEmailAndPassword(auth,email,password)
      }catch(error){
        switch(error.code){
          case 'auth/user-not-fount':
            alert('유저정보못찾음')
            break
          default:
            alert('먼가에러')
        }
        return 
      }
      commit('SET_USER',auth.currentUser)
      router.push('/')
    },
    async register({commit},details){
      const { email,password } = details
      try {
        await createUserWithEmailAndPassword(auth,email,password)
      }catch(error){
        switch(error.code){
          case 'auth/email-already-in-use':
            alert('이미사용중인 이메일입니다')
            break
          default:
            alert('먼가에러')
        }
        return 
      }
      commit('SET_USER',auth.currentUser)
      router.push('/')
    },
    async logout({commit}){
      await signOut(auth)
      commit('CLEAR_USER')
      router.push('/login')
    },
    async getNftData({commit}){
      let snapshotNfts = await getDocs(nftsRef);
      let nfts = [];
      snapshotNfts.forEach(nft=>{
        let nftid = nft.id
        let nftdata = nft.data()
        nftdata.id = nftid
        nfts.push(nftdata)
        
      })
      commit('SET_NFT_DATA',nfts)
      commit('SET_BLANK_NFT_DATA',nfts)

    },
    async getNftDataDetail({commit},id){
      // 메인디테일 받아오는 부분
      // commit('SET_NFT_DETAIL_DATA',null)
      // commit('SET_ON_LOADING')
      
      let detailRef = doc(db, "nfts", id);
      let detailNft = await (await getDoc(detailRef)).data();
      detailNft.Id = id

      
      //detail - info 정보 받아오는부분(백서,로드맵)
      let infos = []
      let infoRef = collection(db,'nfts',id,'info')
      let snapshotInfoNfts = await getDocs(infoRef)
      snapshotInfoNfts.forEach(info=>{
        let infoData = info.data()
        infoData.id = info.id
        infos.push(infoData)
      })
      detailNft.info = infos

      // 민팅정보 받아오는부분
      let mintings = []
      let mintingRef = collection(db,'nfts',id,'minting')
      let queryMinting = query(mintingRef,orderBy('date','asc'))
      let snapshotMintingNfts = await getDocs(queryMinting)
      snapshotMintingNfts.forEach(minting=>{
        let mintingData = minting.data()
        mintingData.id = minting.id
        mintings.push(mintingData)
      })
      detailNft.mintings=mintings

      //댓글 받는부분
      let comments = []
      let commentsRef = collection(db,'nfts',id,'comment')
      let snapshotComment = await getDocs(commentsRef)
      snapshotComment.forEach(comment=>{
        let commentData = comment.data()
        commentData.id = comment.id
        comments.push(commentData)
      })
      detailNft.comment = comments

      commit('SET_NFT_DETAIL_DATA',detailNft)
      // commit('SET_OFF_LOADING')
    },
    
    change_select_status({commit},data){
        commit('SET_BLANK_NFT_DATA',data)
    },
    set_off_loading({commit}){
      commit('SET_OFF_LOADING')
    }
    
  },
  
})
